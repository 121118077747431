
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'GDPR',
  emits: ['title'],
  setup(_, { emit }) {
    const title = 'Q CELLS GDPR';
    emit('title', title);
    return { title };
  },
});
